.footer-div{
    background-color: rgb(53, 51, 51);
    text-align: center;
}

.footer-a{
    margin-top: 10%;
    margin-bottom: 10%;
}

#linkedin-a{
    margin-left: 2.5%;
    margin-right: 2.5%;
}

