.welcome-main-body{
    padding-top: 5%;
    padding-left:20%;
    padding-right:20%;
    margin-bottom: 15%;
}
.text-title{
    font-style:italic;
}

#salute-all{
    font-family: 'Montserrat', sans-serif;
    padding-top:20%;
}

.icon-cover{
    font-size: 25px;
    margin-right: 2.5%;
    cursor: pointer;
}