@import url(https://fonts.googleapis.com/css2?family=Quicksand&display=swap);
.welcome-main-body{
    padding-top: 5%;
    padding-left:20%;
    padding-right:20%;
    margin-bottom: 15%;
}
.text-title{
    font-style:italic;
}

#salute-all{
    font-family: 'Montserrat', sans-serif;
    padding-top:20%;
}

.icon-cover{
    font-size: 25px;
    margin-right: 2.5%;
    cursor: pointer;
}
.main-body{
    background-color: rgb(53, 51, 51);
    color: white;
    padding-top: 5%;
    padding-bottom: 5%;
}
#image-about{
    width: 400px;
    float: left;
}
.snippet{
    font-style: italic;
}
.about-me{
    margin-bottom: 5px;
}

.skills-title{
    font-weight: bold;
}

#front-end{
    margin-bottom: 2.5%;
}

#dev-skills{
    margin-bottom: 2.5%;
    margin-top: 2.5%;
}

.skills{
   margin-right: 2.5%; 
   margin-bottom: 2.5%;
}

.express-skills{
    float: left;
    padding-top: 3%;
    margin-right: 2.5%;
}

.hr-about{
    background-color: white;
}
.project-container{
    margin-top: 5%;
    margin-bottom: 5%;
}

/* Projects img left - text right */

.project-img-left{
    height: 15rem;
    float: left;
    margin-bottom: 5%;
    margin-left: 2.5%;
}

.title-right{
    margin-left: 35%;
}

.description-right{
    margin-left: 35%;
    margin-top: 1.5%;
    margin-right: 5%;
}

.technologies-used-right{
    margin-left: 35%;
    margin-top: 2.5%;
    font-weight: bold;
}

.logo-right{
    margin-left: 4.5%; 
    margin-top: 1.5%;  
}

.a-div-right{
    margin-top: 3%;
    margin-left: 32%;
}
.a-right{
    margin-left: 5%;
}

.hr-line{
    width: 75%;
    height: 5px;
}

/* Projects img right - text left */

.project-img-right{
    height: 20rem;
    float: right;
    margin-bottom: 5%;
    margin-right: 2.5%;
}
.title-left{
    margin-left: 5%;
}
.description-left{
    margin-left: 5%;
    margin-top: 1.5%;
    margin-right: 30%;
}
.technologies-used-left{
    margin-left: 5%;
    margin-top: 2.5%;
    font-weight: bold;
}
.logo-left{
    margin-left: 4.5%; 
    margin-top: 1.5%;  
}
.a-div-left{
    margin-top: 3%;

}
.footer-div{
    background-color: rgb(53, 51, 51);
    text-align: center;
}

.footer-a{
    margin-top: 10%;
    margin-bottom: 10%;
}

#linkedin-a{
    margin-left: 2.5%;
    margin-right: 2.5%;
}


*{
    font-family: 'Quicksand', sans-serif;
}

